import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { track } from '@vercel/analytics/react';

export const Certifications = () => {
  const certifications = [
    {
      title: "Introduction to Python",
      description: "Harvard CS50",
      date: "Issued January 2024",
    },
    {
      title: "Intermediate SQL",
      description: "Hackerrank",
      date: "Issued October 2024",
    },
    {
      title: "Certified RestAPI Developer",
      description: "Hackerrank",
      date: "Issued October 2024",
    },
  ];

  return (
    <section className="certification" id="certifications">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => {
                if (isVisible) {
                  document.title = "Owen Orcan | Certifications";
                  track('viewed_certifications_section');
                }
                return (
                  <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <h2>Certifications</h2>
                    <p>Here are some of the certifications and licenses I've earned to validate my skills and expertise in cloud computing, software development, and technology.</p>
                    <Row>
                      {certifications.map((cert, index) => (
                        <Col key={index} md={4} className="cert-card">
                          <div className="certification-card">
                            <h5>{cert.title}</h5>
                            <p>{cert.description}</p>
                            <span>{cert.date}</span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                );
              }}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
