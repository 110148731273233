import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { track } from '@vercel/analytics/react';
import './Projects.css';
import { useEffect } from "react";

export const Projects = () => {
  const projects = [
    {
      title: "Pixel Punch-Out",
      description: "Pixel Punch-Out is a dynamic multiplayer pixel art fighting game where players compete using customizable pixel characters. Designed for both casual and competitive gaming, this project combines retro-style visuals with modern multiplayer capabilities. Explore unique moves, vibrant levels, and real-time gameplay, perfect for arcade game enthusiasts and pixel art lovers.",
      imgUrl: projImg1,
    },
    {
      title: "Yirabot Web Crawler/Scraper",
      description: "YiraBot is a powerful, Python-based web scraping tool tailored for fast, automated data extraction from websites. With versatile scraping capabilities, YiraBot makes it easy to gather and structure online data for analytics, research, and content creation. Ideal for developers and data analysts, this project simplifies data scraping with efficient and secure crawling",
      imgUrl: projImg2,
    },
    {
      title: "New Project On The Way!",
      description: "I am working on a new project and it will soon be available.",
      imgUrl: projImg3,
    },
  ];

  useEffect(() => {
    document.title = "Owen Orcan | Projects";
  }, []);

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                if (isVisible) {
                  document.title = "Owen Orcan | Projects";
                  track('viewed_projects_section');  // Explicitly track the component view
                }
                return (
                  <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <h2>Projects</h2>
                    <p>Here are some of my key projects... <br /> View more details about the projects by clicking the github icon on the top right</p>
                    <Row>
                      {projects.map((project, index) => (
                        <ProjectCard
                          key={index}
                          {...project}
                          onHover={() => track('project_viewed', { project: project.title })}  // Track each project view on hover
                        />
                      ))}
                    </Row>
                  </div>
                );
              }}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
