import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
import { track } from '@vercel/analytics/react';
import TrackVisibility from 'react-on-screen';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  // Track carousel arrow clicks
  const handleBeforeChange = (previousSlide, nextSlide) => {
    const direction = nextSlide > previousSlide ? 'right' : 'left';
    track('carousel_arrow_clicked', { direction });
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <TrackVisibility>
              {({ isVisible }) => {
                if (isVisible) {
                  document.title = "Owen Orcan | Skills";
                  track('viewed_skills_section');
                }
                return (
                  <div className={isVisible ? "skill-bx wow zoomIn animate__animated animate__fadeIn" : "skill-bx wow zoomIn"}>
                    <h2>Skills</h2>
                    <p>This section highlights my key technical strengths, showcasing the tools and technologies I’ve worked with across various projects.<br />It reflects my ability to adapt, learn, and apply new skills in different development environments, ensuring high-quality results.</p>
                    <Carousel
                      responsive={responsive}
                      infinite={true}
                      beforeChange={handleBeforeChange}
                      className="owl-carousel owl-theme skill-slider"
                    >
                      <div className="item">
                        <img src={meter1} alt="Backend Web Development" />
                        <h5>Backend Web Development</h5>
                      </div>
                      <div className="item">
                        <img src={meter2} alt="Software Development" />
                        <h5>Software Development</h5>
                      </div>
                      <div className="item">
                        <img src={meter3} alt="Problem Solving" />
                        <h5>Problem Solving</h5>
                      </div>
                      <div className="item">
                        <img src={meter1} alt="Data Structures and Algorithms" />
                        <h5>Data Structures and Algorithms</h5>
                      </div>
                    </Carousel>
                  </div>
                );
              }}
            </TrackVisibility>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};
