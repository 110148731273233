import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import { track } from '@vercel/analytics/react';

export const Footer = () => {
  const handleSocialClick = (platform) => {
    track('social_link_clicked', { platform });
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/owenorcan/" onClick={() => handleSocialClick('LinkedIn')}>
                <img src={navIcon1} alt="Linkedin Icon" />
              </a>
              <a href="https://github.com/owenorcan" onClick={() => handleSocialClick('GitHub')}>
                <img src={navIcon2} alt="Icon" />
              </a>
            </div>
            <p>Owen Orcan | 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
